export const aeroportosDisponiveis = [
    'GGB - Água Boa - MT',
    'ALQ - Alegrete - RS',
    'ALT - Alenquer - PA',
    'GGF - Almeirim - PA',
    'AFL - Alta Floresta - MT',
    'ATM - Altami - PA',
    'GDR - Angra dos Reis - RJ',
    'APU - Apucarana - PR',
    'IUP - Apuí - AM',
    'AJU - Aracaju - SE',
    'ARX - Aracati - CE',
    'ARU - Araçatuba - SP',
    'AUX - Araguaína - TO',
    'APX - Arapongas - PR',
    'AAG - Arapoti - PR',
    'AQA - Araraquara - SP',
    'JAW - Araripina - PE',
    'AAX - Araxá - MG',
    'AIR - Aripuanã - MT',
    'BZC - Armação dos Búzios - RJ',
    'AAI - Arraias - TO',
    'AIF - Assis - SP',
    'QVP - Avaré - SP',
    'BGX - Bagé - RS',
    'BAZ - Barcelos - AM',
    'BQQ - Barra - BH',
    'BPG - Barra do Garças - MT',
    'BRA - Barreiras - BH',
    'BRB - Barreirinhas - MA',
    'BAT - Barretos - SP',
    'BAU - Bauru - SP',
    'BEL - Belém - PA',
    'PLU - Belo Horizonte - MG',
    'CNF - Confins - MG',
    'BVB - Boa Vista - Roraima',
    'LAZ - Bom Jesus da Lapa - BH',
    'BYO - Bonito - MS',
    'RBB - Borba - AM',
    'BJP - Bragança Paulista - SP',
    'BSB - Brasília - DF',
    'BVS - Breves - PA',
    'CFB - Cabo Frio - RJ',
    'CFC - Caçador - SC',
    'CDI - Cachoeiro de Itapemirim - ES',
    'OAL - Cacoal - RO',
    'CLV - Caldas Novas - GO',
    'CPV - Campina Grande - PB',
    'CPQ - Campinas - SP',
    'VCP - Campinas - SP',
    'CGR - Campo Grande - MS',
    'CBW - Campo Mourão - PR',
    'CAW - Campos dos Goytacazes - RJ',
    'CQA - Canarana - MT',
    'CEL - Canela - RS',
    'CKS - Carajás - PA',
    'CAF - Carauari - AM',
    'CRQ - Caravelas - BH',
    'CLN - Carolina - MA',
    'CAU - Caruaru - PE',
    'CAC - Cascavel - PR',
    'CXJ - Caxias do Sul - RS',
    'XAP - Chapecó - SC',
    'QCN - Cianorte - PR',
    'CIZ - Coari - AM',
    'CDJ - Conceição do Araguaia - PA',
    'CCI - Concórdia - SC',
    'CFO - Confresa - MT',
    'CKO - Cornélio Procópio - PR',
    'EEA - Correia Pinto - SC',
    'CMG - Corumbá - MS',
    'CTS - Crateús - CE',
    'CCM - Criciúma - SC',
    'CZS - Cruzeiro do Sul - AC',
    'CGB - Cuiabá - MT',
    'BFH - Curitiba - PR',
    'CWB - Curitiba - PR',
    'DTI - Diamantina - MG',
    'DIQ - Divinópolis - MG',
    'DOU - Dourados - MS',
    'ERN - Eirunepé - AM',
    'ERM - Erechim - RS',
    'FEJ - Feijó - AC',
    'FEC - Feira de Santana - BH',
    'FEN - Fernando de Noronha - PE',
    'FLN - Florianópolis - SC',
    'FBA - Fonte Boa - AM',
    'FOR - Fortaleza - CE',
    'IGU - Foz do Iguaçu - PR',
    'FRC - Franca - SP',
    'FBE - Francisco Beltrão - PR',
    'QGP - Garanhuns - PE',
    'GYN - Goiânia - GO',
    'GVR - Governador Valadares - MG',
    'GGJ - Guaíra - PR',
    'GNM - Guanambi - BH',
    'GUZ - Guarapari - ES',
    'GPB - Guarapuava - PR',
    'GUJ - Guaratinguetá - SP',
    'GRP - Gurupi - TO',
    'HUW - Humaitá - AM',
    'QIG - Iguatu - CE',
    'IJU - Ijuí - RS',
    'IOS - Ilhéus - BH',
    'IMP - Imperatriz - MA',
    'IPN - Ipatinga - MG',
    'ITB - Itaituba - PA',
    'TTY - Itanhaém - SP',
    'ITP - Itaperuna - RJ',
    'ITR - Itumbiara - GO',
    'JJG - Jaguaruna - SC',
    'JNA - Januária - MG',
    'JJD - Jijoca de Jericoacoara - CE',
    'JPR - Jací Paraná - RO',
    'JCB - Joaçaba - SC ',
    'JPA - João Pessoa - PB',
    'JOI - Joinville - SC',
    'JUA - Juara - MT',
    'JDO - Juazeiro do Norte - CE',
    'JIA - Juína - MT',
    'JDF - Juiz de Fora - MG',
    'IZA - Juiz de Fora - MG',
    'QDV - Jundiaí - SP',
    'JRN - Juruena - MT',
    'JRT - Juruti - PA',
    'LBR - Lábrea - AM',
    'LAJ - Lages - SC',
    'LEC - Lençóis - BH',
    'LIP - Lins - SP',
    'LDB - Londrina - PR',
    'LVR - Lucas do Rio Verde - MT',
    'MEA - Macaé - RJ',
    'MCP - Macapá - AP',
    'MCZ - Maceió - AL',
    'MAO - Manaus - AM',
    'MNX - Manicoré - AM',
    'MAB - Marabá - PA',
    'MII - Marília - SP',
    'MGF - Maringá - PR',
    'MBK - Matupá - MT',
    'MBZ - Maués - AM',
    'MQH - Minaçu - GO',
    'MTE - Monte Alegre - PA',
    'MOC - Montes Claros - MG',
    'MVF - Mossoró - RN',
    'MVS - Mucuri - BH',
    'NAT - Natal - RN',
    'NVT - Navegantes - SC',
    'DNM - Nova Mutum - MT',
    'NPR - Novo Progresso - PA',
    'OBI - Óbidos - PA',
    'OYK - Oiapoque - AP',
    'ORX - Oriximiná - PA',
    'OIA - Ourilândia do Norte - PA',
    'OUS - Ourinhos - SP',
    'PMW - Palmas - TO',
    'PNG - Paranaguá - PR',
    'PVI - Paranavaí - PR',
    'JPY - Paraty - RJ',
    'PIN - Parintins - AM',
    'PHB - Parnaíba - PI',
    'PFB - Passo Fundo - RS',
    'PTO - Pato Branco - PR',
    'POJ - Patos de Minas - MG',
    'PAV - Paulo Afonso - BH',
    'PET - Pelotas - RS',
    'PNZ - Petrolina - PE',
    'PCS - Picos - PI',
    'PBQ - Pimenta Bueno - RO',
    'POO - Poços de Caldas - MG',
    'PGZ - Ponta Grossa - PR',
    'PMG - Ponta Porã - MS',
    'LCB - Pontes e Lacerda - MT',
    'POA - Porto Alegre - RS',
    'PTQ - Porto de Moz - PA',
    'PNB - Porto Nacional - TO',
    'BPS - Porto Seguro - BH',
    'TMT - Porto de Trombetas - PA',
    'RPU - Porto Urucu - AM',
    'PVH - Porto Velho - RO',
    'PPB - Presidente Prudente - SP',
    'REC - Recife - PE',
    'RDC - Redenção - PA',
    'REZ - Resende - RJ',
    'RAO - Ribeirão Preto - SP',
    'RBR - Rio Branco - AC',
    'GIG - Rio de Janeiro - RJ',
    'RRJ - Rio de Janeiro - RJ',
    'SDU - Rio de Janeiro - RJ',
    'RIG - Rio Grande - RS',
    'RVD - Rio Verde - GO',
    'ROO - Rondonópolis - MT',
    'SSA - Salvador da BH - BH',
    'CSU - Santa Cruz do Sul - RS',
    'IRZ - Santa Isabel do Rio Negro - AM',
    'RIA - Santa Maria - RS',
    'SRA - Santa Rosa - RS',
    'STZ - Santa Terezinha - MT',
    'CMP - Santana do Araguaia - PA',
    'STM - Santarém - PA',
    'GEL - Santo Ângelo - RS',
    'IPG - Santo Antônio do Içá - AM',
    'SSZ - Santos - SP',
    'JSB - São Benedito - CE',
    'JBS - São Borja - RS',
    'QSC - São Carlos - SP',
    'SXO - São Félix do Araguaia - MT',
    'SXX - São Félix do Xingu - PA',
    'SJL - São Gabriel da Cachoeira - AM',
    'JDR - São João del-Rei - MG',
    'SJP - São José do Rio Preto - SP',
    'SJK - São José dos Campos - SP',
    'SSO - São Lourenço - MG',
    'SLZ - São Luís - MA',
    'SQX - São Miguel do Oeste - SC',
    'RTE - São Paulo - SP',
    'CGH - Congonhas - SP',
    'SAO - São Paulo - SP',
    'GRU - Guarulhos - SP',
    'JHF - São Roque - SP',
    'OLC - São Paulo de Olivença - AM',
    'SRN - São Raimundo Nonato - PI',
    'SET - Serra Talhada - PE',
    'OPS - Sinop - MT',
    'QBX - Sobral - CE',
    'SOD - Sorocaba - SP',
    'SMT - Sorriso - MT',
    'TBT - Tabatinga - AM',
    'TGQ - Tangará da Serra - MT',
    'TRQ - Tarauacá - AC',
    'TFF - Tefé - AM',
    'TXF - Teixeira de Freitas - BH',
    'TEC - Telêmaco Borba - PR',
    'TFL - Teófilo Otoni - MG',
    'THE - Teresina - PI',
    'TOW - Toledo - PR',
    'TSQ - Torres - RS',
    'TJL - Três Lagoas - MS',
    'TUR - Tucuruí - PA',
    'UBT - Ubatuba - SP',
    'UBA - Uberaba - MG',
    'UDI - Uberlândia - MG',
    'UMU - Umuarama - PR',
    'UNA - Una - BH',
    'UVI - União da Vitória - PR',
    'URG - Uruguaiana - RS',
    'VCC - Vacaria - RS',
    'VAL - Valença - BH',
    'VAG - Varginha - MG',
    'VIA - Videira - SC',
    'VLP - Vila Rica - MT',
    'BVH - Vilhena - RO',
    'VIX - Vitória - ES',
    'VDC - Vitória da Conquista - BH',
    'XIG - Xinguara - PA',
    'ATL - Atlanta - EUA',
    'LAX - Los Angeles - EUA',
    'ORD - Chicago - EUA',
    'DFW - Dallas - EUA',
    'JFK - Nova Iorque - EUA',
    'YYZ - Toronto - Canadá',
    'YVR - Vancouver - Canadá',
    'MEX - Cidade do México - México',
    'SJO - San José - Costa Rica',
    'PTY - Cidade do Panamá - Panamá',
    'SAL - San Salvador - El Salvador',
    'EZE - Buenos Aires - Argentina',
    'LIM - Lima - Peru',
    'BOG - Bogotá - Colômbia',
    'SDQ - Santo Domingo - República Dominicana',
    'PUJ - Punta Cana - República Dominicana',
    'MBJ - Montego Bay - Jamaica',
    'SJU - San Juan - Porto Rico',
    'LHR - Londres - Reino Unido',
    'CDG - Paris - França',
    'FRA - Frankfurt - Alemanha',
    'AMS - Amsterdã - Países Baixos',
    'MAD - Madri - Espanha',
    'FCO - Roma - Itália',
    'ZRH - Zurique - Suíça',
    'IST - Istambul - Turquia',
    'CPH - Copenhague - Dinamarca',
    'DUB - Dublin - Irlanda',
    'MUC - Munique - Alemanha',
    'BCN - Barcelona - Espanha',
    'SVO - Moscou - Rússia',
    'ARN - Estocolmo - Suécia',
    'PRG - Praga - República Tcheca',
    'ATH - Atenas - Grécia',
    'WAW - Varsóvia - Polônia',
    'OSL - Oslo - Noruega',
    'BRU - Bruxelas - Bélgica',
    'VIE - Viena - Áustria',
    'LGW - Londres - Reino Unido',
    'MAN - Manchester - Reino Unido',
    'TXL - Berlim - Alemanha',
    'MXP - Milão - Itália',
    'LIS - Lisboa - Portugal',
    'GVA - Genebra - Suíça',
    'HEL - Helsinque - Finlândia',
    'VCE - Veneza - Itália',
    'ZAG - Zagreb - Croácia',
    'OTP - Bucareste - Romênia',
    'EDI - Edimburgo - Reino Unido',
    'HAM - Hamburgo',
    'HND - Tóquio - Japão',
    'PEK - Pequim',
    'DXB - Dubai',
    'HKG - Hong Kong',
    'ICN - Seul - Coreia do Sul',
    'DEL - Nova Delhi - Índia',
    'SIN - Singapura',
    'KUL - Kuala Lumpur - Malásia',
    'BKK - Bangcoc - Tailândia',
    'CGK - Jacarta - Indonésia',
    'CAN - Cantão - China',
    'TPE - Taoyuan - Taiwan',
    'KMG - Kunming - China',
    'SHA - Xangai - China',
    'PVG - Xangai - China',
    'SYD - Sydney - Austrália',
    'BOM - Mumbai - Índia',
    'KIX - Osaka - Japão',
    'CTU - Chengdu - China',
    'RUH - Riade - Arábia Saudita',
    'MNL - Manila - Filipinas',
    'DOH - Doha - Catar',
    'NRT - Tóquio - Japão',
    'AUH - Abu Dhabi - Emirados Árabes Unidos',
    'JNB - Joanesburgo - África do Sul',
    'CAI - Cairo - Egito',
    'ADD - Adis Abeba - Etiópia',
    'NBO - Nairóbi - Quênia',
    'LOS - Lagos - Nigéria',
    'CPT - Cidade do Cabo - África do Sul',
    'DKR - Dakar - Senegal',
    'JIB - Djibouti',
    'KGL - Kigali - Ruanda',
    'TUN - Túnis - Tunísia',
    'ZNZ - Zanzibar - Tanzânia',
    'CMN - Casablanca - Marrocos',
    'ACC - Acra - Gana',
    'ALG - Argel - Argélia',
    'DUR - Durban - África do Sul',
    'HAH - Moroni - Comores',
    'CHC - Christchurch - Nova Zelândia',
    'SYD - Sydney - Austrália',
    'AKL - Auckland - Nova Zelândia',
    'MEL - Melbourne - Austrália',
    'BNE - Brisbane - Austrália',
    'PER - Perth - Austrália',
    'ADL - Adelaide - Austrália',
    'WLG - Wellington - Nova Zelândia',
    'OOL - Gold Coast - Austrália',
    'HNL - Honolulu - Havaí, EUA',
    'NOU - Nouméa - Nova Caledônia',
    'PPT - Papeete - Taiti',
    'APW - Apia - Samoa',
    'RAR - Rarotonga - Ilhas Cook',
    'NAN - Nadi - Fiji',
]
